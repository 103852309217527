<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/light.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <v-img
            class="mx-auto"
            width="33%"
            :src="require('@/assets/ICSA.png')"
          />
          <base-subheading
            style="padding-top: 25px;"
            weight="regular"
            title="HEDRON STAKING IS HERE"
            class="text-center"
          />

          <base-heading
            size="text-h2"
            title="WELCOME TO ICOSA"
            weight="medium"
            class="text-center"
          />

          <base-body
          class="text-center"
          >
            Icosa (ICSA) is a protocol which builds upon the Hedron protocol to add HDRN staking and additional incentives for staking HEX as well as jumpstarting the Hedron borrowing system. Icosa is built by the same team behind the Hedron protocol, ensuring seamless integration.
          </base-body>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
